import React, { useState,useEffect,useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import { Button, Tabs,Spinner } from "flowbite-react";
import EventsManagementCard from "../Cards/Account/EventsManagementCard";
import { useNavigate,useParams } from "react-router-dom";
import axios from 'axios';
import MainPagination from "../Miscellanous/MainPagination";


function Events() {
  const { user,token,organizer,organizerlog,logout,request} = useAuth();


  const navigate=useNavigate();
  // const {request}=useParams();
  const [details,setDetails]=useState([]);
  const [organizers,setOrganizers]=useState([]);
  const handleOrganizer=()=>{
    if(user.userrolename=="Premium User"){
      navigate("/eventorganizer/dashboard");

    }
  }

  const handlerequest=()=>{
    navigate("/organizerrequest");
}
const initialPayload = {
  currentpage: 1,
};

const [formData, setFormData] = useState(initialPayload);
const loadedOnce=useRef(false);
const organizeOnce=useRef(false);
const url =process.env.REACT_APP_API_URL;
const [ perpage, setPerpage ] = useState(1);
  const [total, setTotal] = useState(0);
  const [to_no, setTo] = useState(0);
  const [from_no, setFrom] = useState(0);
// get_events_logged_in_user

const handleParamChange = (name, value) => {
  setFormData((prevPayload) => ({
    ...prevPayload,
    [name]: value,
  }));
  loadedOnce.current=false;
};

useEffect(()=>{
  if (loadedOnce.current) return;
  loadedOnce.current = true;
  handleevents();


},[loadedOnce]);


useEffect(() => {
  if (organizeOnce.current) return;
  organizeOnce.current = true;
  handleorganizer();
  
}, []);

const handleevents= async() => {
  const additionalPath = `${url}/get_events_logged_in_user`;
  const headers = {
      'Content-Type': 'application/json',
  };

  if(token){
    headers['Authorization'] = `Bearer ${token}`;
  }

  await axios.get(additionalPath, {params:formData,headers: headers })
      .then(response => {
        console.log(response.data.events)
           setDetails(response.data.events.data);
           setTotal(response.data.events.total)
           setTo(response.data.events.to)
           setFrom(response.data.events.from)
           setPerpage(response.data.events.per_page)
           loadedOnce.current = true;
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log('Unauthorized: Logging user out or performing specific action...');
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
 // Rethrow the error for further handling if needed
        }
      });

}

const handleorganizer= async() => {
  const additionalPath = `${url}/get_event_organizer_details`;
  const headers = {
      'Content-Type': 'application/json',
  };

  if(token){
    headers['Authorization'] = `Bearer ${token}`;
  }

  await axios.get(additionalPath, {headers: headers })
      .then(response => {
          console.log(response.data.event_organizer);

          organizerlog(response.data.event_organizer);
          setOrganizers(response.data.event_organizer);
      
          
          
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log('Unauthorized: Logging user out or performing specific action...');
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
         // Rethrow the error for further handling if needed
        }
      });

}

const active=details.filter(item => item.status==="active");
const closed=details.filter(item => item.status==="closed");

console.log(organizers);
const itemsPerPage = details&& perpage;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the range of items for the current page
  const startIndex = from_no;
  const endIndex = to_no ;
  const totalPages = Math.ceil(total / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleParamChange("currentpage", newPage);
  };

  return user.userrolename=="Premium User" ? (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <p className="font-bold">Event Management</p>
        {organizers&&organizers.length!=0&&
          <Button className="bg-normalbrown w-1/4" onClick={handleOrganizer}>
            Open Console
          </Button>
      }
      </div>
      <div>
  <Tabs.Group aria-label="Tabs with underline" style="fullWidth">
    <Tabs.Item title="Opened">
      {loadedOnce.current==true ? (
        active && active.length !== 0 ? (
          <div className="flex flex-col gap-2">
            {active.map((event, index) => (
              <EventsManagementCard
                key={index}
                name={event.name}
                date={event.date}
                location={event.location}
              />
            ))}
          </div>
        ) : (
          <p className="text-darkgray">No opened events</p>
        )
      ) : (
        // Loading spinner while fetching data
        <div className="text-darkgray text-center">
          <div className="inset-0 flex items-center justify-center z-50">
            <div className="p-4 max-w-md mx-auto rounded-md">
              <Spinner aria-label="Default status example" color="warning" size="xl" />
            </div>
          </div>
          Loading opened events...
        </div>
      )}
      <MainPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
    </Tabs.Item>
    <Tabs.Item title="Closed">
      {loadedOnce.current==true ? (
        closed && closed.length !== 0 ? (
          <div className="flex flex-col gap-2">
            {closed.map((event, index) => (
              <EventsManagementCard
                key={index}
                name={event.name}
                date={event.date}
                location={event.location}
              />
            ))}
          </div>
        ) : (
          <p className="text-darkgray">No closed events</p>
        )
      ) : (
        // Loading spinner while fetching data
        <div className="text-darkgray text-center">
          <div className="inset-0 flex items-center justify-center z-50">
            <div className="p-4 max-w-md mx-auto rounded-md">
              <Spinner aria-label="Default status example" color="warning" size="xl" />
            </div>
          </div>
          Loading closed events...
        </div>
      )}
    </Tabs.Item>
  </Tabs.Group>
</div>
    </div>
  ) : (
    

        request?(<div className="flex flex-col items-center justify-center gap-1 sm:p-24 p-8">
        <p className="font-bold">
          Your Request has been sent{" "}
        </p>
        <p className="text-darkgray text-center">
          You will soon be the owner of your own events and create endless memories on
          KriffeOnline{" "}
        </p>
        <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-4">
  <div class="bg-normalblue h-2.5 rounded-full w-[50%] "></div>
  <p className="text-center">50%</p>
</div>
        {/* put a prohress bar here */}
      </div>):(<div className="flex flex-col items-center justify-center gap-1 p-24">
        <p className="font-bold">
          Verify your organization or fellowship details{" "}
        </p>
        <p className="text-darkgray">
          Be the owner of your own events and create endless memories on
          KriffeOnline{" "}
        </p>
        <Button className="bg-normalbrown rounded-md p-2 w-1/3" onClick={handlerequest}>Verify</Button>
      </div>)
    
    
  );
}
export default Events;
