import React, { useState, useEffect } from "react";
import { Button, Label, TextInput, FileInput, Spinner } from "flowbite-react";
import { useMarketplaceContext } from "../../../context/MarketplaceContexts";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";
import AWS from "aws-sdk";


function ReviewForm() {
  const { isLogged, token, logout, user } = useAuth();

  const {
    temporaryAdInfo,
    addAds,
    addTemporaryAdInfo,
    location,
    categories,
    adtype,
    condition,
    flexibility,
    agegroup,
    material,
    style,
    format,
    language,
  } = useMarketplaceContext();
  const [err, setErr] = useState("");
  const [id, setId] = useState("");
  const [selectimages, setImages] = useState([]);
  const [inputValue, setInputValue] = useState(temporaryAdInfo.tags);
  const navigate = useNavigate();
  const selectedLoc =
    location && location.find((spec) => spec.id === temporaryAdInfo.location);
  const selectedSpec =
    categories &&
    categories.find((spec) => spec.id === temporaryAdInfo.category);
  const selectedCond =
    condition &&
    condition.find((spec) => spec.id === temporaryAdInfo.condition);
  const selectedAd =
    adtype && adtype.find((spec) => spec.id === temporaryAdInfo.ad_type_id);
  const [success, setSuccess] = useState(false);
  const [payment, setPayment] = useState(false);

  const displayProductDetails = temporaryAdInfo.product_details.map(
    (obj, index) => {
      const { attribute_name, attribute_value } = obj;
      let associatedName = "";
      let name = "";

      switch (attribute_name) {
        case "age_group":
          const ageGroupValue = agegroup.find(
            (item) => item.id === attribute_value
          );
          if (ageGroupValue) {
            name = "Age group";

            associatedName = ageGroupValue.age_group_label;
          }
          break;

        case "product_flexibility":
          const productFlexibilityValue = flexibility.find(
            (item) => item.id === attribute_value
          );
          if (productFlexibilityValue) {
            name = "Flexibility";
            associatedName = productFlexibilityValue.flexibility_label;
          }
          break;

        case "material":
          const materialValue = material.find(
            (item) => item.id === attribute_value
          );
          if (materialValue) {
            name = "Material";
            associatedName = materialValue.material_label;
          }
          break;

        case "style":
          const styleValue = style.find((item) => item.id === attribute_value);
          if (styleValue) {
            name = "Style";
            associatedName = styleValue.style_label;
          }
          break;

        case "format":
          const FormatValue = format.find(
            (item) => item.id === attribute_value
          );
          if (FormatValue) {
            name = "Format";
            associatedName = FormatValue.format_label;
          }
          break;

        case "language":
          const languageValue = language.find(
            (item) => item.id === attribute_value
          );
          if (languageValue) {
            name = "Language";
            associatedName = languageValue.language_label;
          }
          break;

        // Add more cases for other attribute_names if needed

        default:
          break;
      }

      return (
        <div className="basis-1/2" key={index}>
          <div className="mb-2 block">
            <Label
              htmlFor="detail"
              value={
                <div>
                  <p className="font-bold text-midnightblue">{name}</p>
                </div>
              }
            />
          </div>

          <TextInput
            id="detail"
            name="detail"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={associatedName}
          />
        </div>
      );
    }
  );

  console.log(temporaryAdInfo.tags)

  const info = {
    name: temporaryAdInfo.name,
    description: temporaryAdInfo.description,
    price: temporaryAdInfo.price,
    location: temporaryAdInfo.location,
    category: temporaryAdInfo.category,
    condition: temporaryAdInfo.condition,
    product_details: temporaryAdInfo.product_details,
    tags: temporaryAdInfo.tags,
  };

  const url =process.env.REACT_APP_API_URL;

  const handleChanges = () => {
    // call the api and get the id
    setSuccess(true);

    const additionalPath = `${url}/add_product`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    // if (token) {
    //   headers['Authorization'] = `Bearer ${token}`;
    // }

    axios
      .post(additionalPath, info, { headers: headers })
      .then((response) => {
        // setProduct(response.data);
        console.log(response.data.product_id);
        console.log("finish");
        setId(response.data.product_id);
        uploadMultipleImages(response.data.product_id);
        // if(selectimages.length!=0){
        //   handleupdate();
        // }else{
        //   console.log("none");
        //     console.log(selectimages);
        // }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setErr(err);

          // Rethrow the error for further handling if needed
        }
      });

    // navigate(`/adspage/${response.data.product_id}/Pending`);
  };

  const idinfo = {
    product_id: id,
  };

  const imageinfo = {
    product_id: id,
    productimages: selectimages,
  };

  useEffect(() => {
    if (selectimages.length === temporaryAdInfo.product_images.length) {
      handleupdate();
    } else {
      console.log("none");
      console.log(selectimages);
    }
  }, [selectimages,temporaryAdInfo]);

  const handleupdate = () => {
    const additionalPath = `${url}/update_product_image`;
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    console.log(imageinfo);

    axios
      .patch(additionalPath, imageinfo, { headers: headers })
      .then((response) => {
        // setProduct(response.data);
        console.log(response.data.responsemessage);
        console.log("finishhh");
        setSuccess(false);
        navigate(`/adspage/${id}/Pending`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setErr(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const uploadMultipleImages = async (id) => {
    try {
      // const promises = temporaryAdInfo.product_images.map(
      //   async (file, index) => {
      //     const base64Response = await fetch(file);
      //     const blob = await base64Response.blob();
      //     const arrayBuffer = await blob.arrayBuffer();
      //     console.log(base64Response);
      //     console.log(blob);
      //     console.log(arrayBuffer);

      //     const params = {
      //       Bucket: "kriffeonline-assets",
      //       Key: `dev/products/${id}_${index + 1}.png`,
      //       Body: arrayBuffer, // Use the ArrayBuffer directly as Body
      //       ACL: "public-read",
      //       ContentType: file.type,
      //     };

      //     return new Promise((resolve, reject) => {
      //       s3.upload(params, (err, data) => {
      //         if (err) {
      //           console.error("Error uploading to S3:", err);
      //           reject(err);
      //         } else {
      //           if (data.Location) {
      //             console.log(`Image ${index + 1} uploaded successfully.`);
      //             console.log(data.Location);
      //             // setImages(prevUrls => [...prevUrls, data.Location]);
      //             resolve(data.Location);
      //           } else {
      //             console.log(`Image ${index + 1} not uploaded.`);
      //             resolve(null); // Resolve with null if upload fails
      //           }
      //         }
      //       });
      //     });
      //   }
      // );

      // const uploadedImages = await Promise.all(promises);
      // const filteredImages = uploadedImages.filter((url) => url !== null); // Filter out failed uploads
      // setImages((prevUrls) => [...prevUrls, ...filteredImages]);
      AWS.config.update({
        accessKeyId: "DO00JYWNXZG6EYV2J4JT",
        secretAccessKey: "QARFr7ME28EltUtflEWNF/2OzR92JJjL1TWjQi+A5po",
        region: "blr1",
      });

      const spacesEndpoint = new AWS.Endpoint(`blr1.digitaloceanspaces.com`);

      const s3 = new AWS.S3({
        endpoint: spacesEndpoint,
      });

      for (let i = 0; i < temporaryAdInfo.product_images.length; i++) {
        const file = temporaryAdInfo.product_images[i];
        const params = {
          Bucket: "kriffeonline-assets",
          Key: `dev/products/${id}_${i + 1}.png`,
          Body: file,
          ACL: "public-read",
        };

        const uploadResult = await s3.upload(params).promise();
        setImages((prevFiles) => [...prevFiles, uploadResult.Location]);
        console.log(uploadResult.Location);
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  return (
    <div className="flex justify-center items-center  h-full relative ">
      <form className="w-full">
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="name"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Product Name</p>
                </div>
              }
            />
          </div>

          <TextInput
            id="name"
            name="name"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={temporaryAdInfo.name}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="description"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Product Description
                  </p>
                </div>
              }
            />
          </div>

          <TextInput
            id="description"
            name="description"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={temporaryAdInfo.description}
          />
        </div>
        <div className="flex flex-row gap-2 w-full">
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="category"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">
                      Product Category
                    </p>
                  </div>
                }
              />
            </div>

            <TextInput
              id="category"
              name="category"
              required
              type="text"
              class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
              value={selectedSpec.name && selectedSpec.name}
            />
          </div>

          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="location"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">
                      Product Location
                    </p>
                  </div>
                }
              />
            </div>

            <TextInput
              id="location"
              name="location"
              required
              type="text"
              class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
              value={selectedLoc.region_name}
            />
          </div>
        </div>

        <div className="flex flex-row gap-2 w-full">
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="condition"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">
                      Product Condition
                    </p>
                  </div>
                }
              />
            </div>

            <TextInput
              id="condition"
              name="condition"
              required
              type="text"
              class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
              value={selectedCond.condition_label}
            />
          </div>
          {/* <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="adtype"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">Ad Specification</p>
                  </div>
                }
              />
            </div>

            <TextInput
              id="adtype"
              name="adtype"
              required
              type="text"
              class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
              value={selectedAd.name}
            />
          </div> */}
        </div>

        <div className=" gap-2 grid grid-cols-2 ">{displayProductDetails}</div>

        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="tags"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Product Tags</p>
                </div>
              }
            />
          </div>

          {/* <TextInput
            id="tags"
            name="tags"
            required
            type="text"
            class="flex mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-auto  flex-wrap"
            value={inputValue}
          /> */}
          <div className="flex flex-wrap gap-2">
          {temporaryAdInfo.tags&&temporaryAdInfo.tags.map((tag,index)=>(
            <p className="p-2 rounded-full bg-lightblue/40 text-normalblue">{tag}</p>
          ))}

          </div>
          
        </div>

        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="detail"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Product Images</p>
                </div>
              }
            />
          </div>

          <div className=" p-2 sm:flex sm:flex-row grid grid-cols-2 gap-2">
            {temporaryAdInfo.product_images.map((image, index) => (
              <img
                key={index}
                src={URL.createObjectURL(image)}
                alt={`Image ${index}`}
                className="sm:h-64 h-36 sm:w-64 w-36 border border-normalblue rounded-md"
              />
            ))}
          </div>
        </div>

        {/* images in preview */}

        <div className="flex justify-end mt-4 ">
          <Button
            type="button"
            className=" bg-normalblue sm:p-4 p-2 rounded-md"
            onClick={handleChanges}
          >
            Post Ad
          </Button>
        </div>
      </form>

      {success && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-30 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default ReviewForm;
