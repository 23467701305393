import React, { useState, useEffect } from "react";
import { Button, Label, TextInput, FileInput } from "flowbite-react";
import { useMarketplaceContext } from "../../../context/MarketplaceContext";
import { useEventOrganizerContext } from "../../../context/EventOrganizerContext";

function EventTicketPackage({ ticketdetails, setticketDetails, toggleDiv }) {
  const [productDetail, setProductDetail] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [formSections, setFormSections] = useState([
    {
      name: "",
      description: "",
      package_info: "",
      price: "",
      package_type: "",
      number_available: 1,
    },
  ]);
  const { addTemporaryEventInfo } = useEventOrganizerContext();

  const addFormSection = () => {
    setFormSections([
      ...formSections,
      {
        name: "",
        description: "",
        package_info: "",
        price: "",
        package_type: "",
        number_available: 1,
      },
    ]);
  };

  const removeFormSection = (index) => {
    if (index > 0) {
      const updatedFormSections = formSections.filter((_, i) => i !== index);
      setFormSections(updatedFormSections);
    }
  };

  const handleValueChange = (e, index) => {
    const { name, value } = e.target;

    // Update the specific field within the selected form section
    const updatedFormSections = [...formSections];
    updatedFormSections[index] = {
      ...updatedFormSections[index],
      [name]: value,
    };

    // Update the formSections state
    setFormSections(updatedFormSections);

    // Update the ticketdetails parameter
    const updatedTicketDetails = [...ticketdetails];
    updatedTicketDetails[index] = {
      ...updatedTicketDetails[index],
      [name]: value,
    };

    // Update the ticketDetails state
    setticketDetails(updatedTicketDetails);
  };
  // Populate form sections with selected values
  useEffect(() => {
    if (ticketdetails.length > 0) {
      const updatedFormSections = ticketdetails.map((detail) => {
        return {
          name: detail.name,
          description: detail.description,
          package_info: detail.package_info,
          price: detail.price,
          package_type: detail.package_type,
          number_available: detail.number_available,
        };
      });
      setFormSections(updatedFormSections);
    }
  }, [ticketdetails]);

  const info = { ticket_type: formSections };
  const [formerror, setFormerror] = useState("");
  const [Err,setErr]=useState(false);

  useEffect(()=>{
    const isAnyFieldEmpty = formSections.some(
    (section) =>
      section.name === "" ||
      section.description === "" ||
      section.package_info === "" ||
      section.package_type === "" ||
      section.number_available === 0
  );

  if (isAnyFieldEmpty) {
    // Display an error message or handle the invalid scenario
    setErr(true);
  } else {
    setErr(false);
  }


  },[formSections,Err]);

  const handleChanges = () => {
    // save into the temp array
    const isAnyFieldEmpty = formSections.some(
      (section) =>
        section.name === "" ||
        section.description === "" ||
        section.package_info === "" ||
        section.price === "" ||
        section.package_type === "" ||
        section.number_available === 0
    );

    if (isAnyFieldEmpty) {
      // alert('Please fill in all fields before proceeding.');
      setFormerror("Please fill in all fields before proceeding."); // Alert for empty fields
      return; // Prevent further execution if any field is empty
    }
    console.log(info);
    addTemporaryEventInfo(info);
    toggleDiv("media");
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div className="flex flex-col justify-between mb-4">
          <p className="font-bold text-midnightblue">Ticketing</p>
          <p className="text-darkgray">
            Specify the different ticket categories for your event. To add
            another pacakge click on the Add Another Detail button
          </p>
        </div>
        <div className="flex justify-end font-bold text-normalbrown ">
          <button onClick={addFormSection}>Add Another Detail +</button>
        </div>
      </div>
      <div>
        <p className="font-bold text-red-600 text-lg">{formerror}</p>
      </div>
      {formSections.map((section, index) => (
        <form
          key={index}
          className="flex w-full flex-col gap-4  rounded-md  mb-5 bg-white p-4"
        >
          <div>
            <div className="flex flex-row justify-between">
              <div className="mb-2 block">
                <Label
                  htmlFor="detail"
                  value={
                    <div>
                      <p className="font-bold text-midnightblue">
                        Ticket Package #{index + 1}
                      </p>
                      <p className="text-darkgray">
                        Specify the different ticket categories for your event
                      </p>
                    </div>
                  }
                />
              </div>
              {index > 0 && (
                <button
                  onClick={() => removeFormSection(index)}
                  className="text-normalbrown font-bold text-md"
                >
                  Remove Form -
                </button>
              )}
            </div>

            <div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="name"
                    value={
                      <div>
                        <p className="font-bold text-midnightblue">
                          Ticket Package Type
                        </p>
                        <p className="text-darkgray">
                          Choose the ticket package type in full.i.e either it
                          is a free ticket or paid ticket
                        </p>
                      </div>
                    }
                  />
                </div>

                <select
                  name="package_type"
                  id="category"
                  class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                  value={section.package_type}
                  onChange={(e) => handleValueChange(e, index)}
                >
                  <option>Select a type</option>

                  <option value="Free">Free</option>
                  <option value="Paid">Paid</option>
                </select>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="name"
                    value={
                      <div>
                        <p className="font-bold text-midnightblue">
                          Ticket Package Name
                        </p>
                        <p className="text-darkgray">
                          Write the ticket package name in full
                        </p>
                      </div>
                    }
                  />
                </div>

                <TextInput
                  id="name"
                  name="name"
                  required
                  type="text"
                  class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                  value={section.name}
                  onChange={(e) => handleValueChange(e, index)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="description"
                    value={
                      <div>
                        <p className="font-bold text-midnightblue">
                          Ticket Package Description
                        </p>
                        <p className="text-darkgray">
                          Write the ticket package description. Justa short
                          summary
                        </p>
                      </div>
                    }
                  />
                </div>

                <TextInput
                  id="description"
                  name="description"
                  required
                  type="text"
                  class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                  value={section.description}
                  onChange={(e) => handleValueChange(e, index)}
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="packageinfo"
                    value={
                      <div>
                        <p className="font-bold text-midnightblue">
                          Ticket Package Information
                        </p>
                        <p className="text-darkgray">
                          Write the ticket package information in full. i.e
                          Anythign benefits that the ticket comes with
                        </p>
                      </div>
                    }
                  />
                </div>

                <TextInput
                  id="packageinfo"
                  name="package_info"
                  required
                  type="text"
                  class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                  value={section.package_info}
                  onChange={(e) => handleValueChange(e, index)}
                />
              </div>
              <div className="flex sm:flex-row flex-col gap-2">
                <div className="basis-1/2">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="price"
                      value={
                        <div>
                          <p className="font-bold text-midnightblue">
                            Ticket Package Price
                          </p>
                          <p className="text-darkgray">
                            Write the ticket package price
                          </p>
                        </div>
                      }
                    />
                  </div>

                  <TextInput
                    id="price"
                    name="price"
                    required
                    type="number"
                    placeholder="0.00"
                    class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                    value={section.price}
                    onChange={(e) => handleValueChange(e, index)}
                  />
                </div>
                <div className="basis-1/2">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="price"
                      value={
                        <div>
                          <p className="font-bold text-midnightblue">
                            Ticket Package Number Available
                          </p>
                          <p className="text-darkgray">
                            Write the number of tickets available
                          </p>
                        </div>
                      }
                    />
                  </div>

                  <TextInput
                    id="price"
                    name="number_available"
                    required
                    type="number"
                    min={1}
                    class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                    value={section.number_available}
                    onChange={(e) => handleValueChange(e, index)}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      ))}
      <div className="flex justify-end font-bold text-normalbrown ">
        <button onClick={addFormSection}>Add Another Detail +</button>
      </div>

      <div className="flex">
        <Button
          type="button"
          className={`justify-end bg-normalblue sm:p-4 p-2 rounded-md ${Err ? "opacity-50 cursor-not-allowed" : ""}`}
          onClick={handleChanges}
        >
          Save Changes & Next
        </Button>
      </div>
    </div>
  );
}

export default EventTicketPackage;
